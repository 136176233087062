import { Types } from '../config/types';
import { useContext } from 'react';
import { dataContext } from '../App';
import styled from 'styled-components';

// #region Styled

const Container = styled.div`
	width: 100%;
	max-width: inherit;
	background-color: var(--white-color);
	border-top: 1px solid var(--soft-gray-color);
	display: none;
	position: fixed;
	bottom: 0;
`;

const Wrapper = styled.div`
	padding: 4px var(--spacing-unit-s1);
	display: flex;
	align-items: center;
	column-gap: var(--spacing-unit-s2);
	
	button {
		flex: 1;
	}
`;

// #endregion

const BottomAction = () => {
	const { state } = useContext(dataContext) as Types;
	const { text: languageText } = state.language as Types;
	
	return (
		<Container className='bottom-action'>
			<Wrapper>
				<button type='button' title={ languageText.add_to_cart } className='button-ghost'>{ languageText.add_to_cart }</button>
				<button type='button' title={ languageText.buy_now } className='button-primary'>{ languageText.buy_now }</button>
			</Wrapper>
			<div className='safearea-bottom' />
		</Container>
	);
}

export default BottomAction;