import { Types } from '../config/types';
import { useContext } from 'react';
import { dataContext } from '../App';
import { useParams/*, useSearchParams*/ } from 'react-router-dom';
import styled from 'styled-components';
import ProductList from '../components/ProductList';
import ProductDescription from '../components/ProductDescription';

// #region Styled

const Main = styled.main`
	flex: 1;
	max-width: inherit;
`;

// #endregion

const Products = () => {
	const { state } = useContext(dataContext) as Types;
	const { text: languageText } = state.language as Types;
	const params = useParams();
	/*const [ searchParams ] = useSearchParams();*/



	/*console.log(searchParams.get('sor'));*/
	/*console.log(searchParams.get('pag'));*/
	


	return (
		<>
			<Main>
				{ !params.productsId
					? <>
						<ProductList helmet={ true } title={ languageText.product_list } option='filter' />
					</>
					: <>
						<ProductDescription helmet={ true } />
					</>
				}
			</Main>
			<div className='groove'></div>
		</>
	);
}

export default Products;