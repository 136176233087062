import { Types } from '../config/types';
import { useContext } from 'react';
import { dataContext } from '../App';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

// #region Styled

const Navigation = styled.nav`
	width: 100%;
	max-width: inherit;
	background-color: var(--white-color);
	border-top: 1px solid var(--soft-gray-color);
	position: fixed;
	bottom: 0;
`;

const Wrapper = styled.div`
	padding: 5px 0;
	display: flex;
	
	div {
		display: flex;
		justify-content: center;
		align-items: center;
		flex: 1;
	}
`;

const NavLinks = styled(NavLink)<{ $icon?: string; }>`
	font-size: 10px;
	line-height: var(--line-height-s2);
	color: var(--black-color);
	min-width: 42px;
	display: flex;
	flex-direction: column;
	align-items: center;
	row-gap: 3px;
	
	&::before {
		content: var(--${ props => props.$icon });
		font-size: var(--font-size-l2);
	}
	
	&.active {
		color: var(--primary-color);
		
		&::before {
			content: var(--${ props => props.$icon }-solid);
		}
	}
`;

// #endregion

const BottomNavigation = () => {
	const { state } = useContext(dataContext) as Types;
	const {
		code: languageCode,
		text: languageText
	} = state.language as Types;
	
	return (
		<Navigation className='bottom-navigation'>
			<Wrapper>
				<div>
					<NavLinks to='/' title={ languageText.home } hrefLang={ languageCode } className='link-icon bophloi' $icon='home'>{ languageText.home }</NavLinks>
				</div>
				<div>
					<NavLinks to={ `/${ languageCode }/products` } title={ languageText.products } className='link-icon bophloi' $icon='product'>{ languageText.products }</NavLinks>
				</div>
				<div>
					<NavLinks to={ `/${ languageCode }/promotions` } title={ languageText.promotions } className='link-icon bophloi' $icon='promotion'>{ languageText.promotions }</NavLinks>
				</div>
				<div>
					<NavLinks to={ `/${ languageCode }/cart` } title={ languageText.cart } className='link-icon bophloi' $icon='cart'>{ languageText.cart }</NavLinks>
				</div>
				<div>
					<NavLinks to={ `/${ languageCode }/member` } title={ languageText.member } className='link-icon bophloi' $icon='member'>{ languageText.member }</NavLinks>
				</div>
			</Wrapper>
			<div className='safearea-bottom' />
		</Navigation>
	);
}

export default BottomNavigation;