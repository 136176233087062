const useHandler = {
	image: (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
		event.currentTarget.style.display = 'none';
		
		switch(event.type) {
			case 'load':
				event.currentTarget.style.display = '';
				break;
			default:
				return null;
		}
	}
}

export default useHandler;